









































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import RunningNumberService from "@/services/RunningNumberService";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ListViewMixin from "@/components/shared/ListViewMixin";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import StringUtil from "@/utilities/StringUtil";
import RouteUtil from "@/utilities/RouteUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { AlertDialogView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 0,
            recordCount: 10,
            loading: false,
        },
        filter: {
            code: "",
            name: "",
        },
        runningNumbers: [],
    }),
})
export default class RunningNumberListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private runningNumberService = new RunningNumberService();

    public get headers() {
        return [
            {
                text: this.$t("text.code"),
                value: "code",
                width: "22%",
            },
            {
                text: this.$t("text.name"),
                value: "name",
            },
            {
                text: this.$t("text.format"),
                value: "format",
                width: "22%",
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                align: "center",
                fixed: true,
                width: "80px",
                sortable: false,
            },
        ];
    }

    public created() {
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt((query.pageNo as any) ?? 1);
        data.table.recordCount = parseInt((query.recordCount as any) ?? 10);
        data.filter.code = query.code ?? "";
        data.filter.name = query.name ?? "";

        this.load();
    }

    public destroyed() {
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;
        try {
            const filter = this.$data.filter;
            const r = await this.runningNumberService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                code: StringUtil.enclose(filter.code, "%", "%"),
                name: StringUtil.enclose(filter.name, "%", "%"),
            });
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const numbers = r.data.runningNumbers;
            this.$data.runningNumbers = numbers.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/settings/running-numbers", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                code: filter.code,
                name: filter.name,
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
    }
}
